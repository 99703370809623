.AlertSubmissionForm {
  width: 100%;
}

.AlertSubmissionForm * {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  margin: 0.2em 0;
}

.AlertSubmissionForm label {
  display: block;
  margin-top: 1em;
}

.AlertSubmissionForm select,
.AlertSubmissionForm textarea,
.AlertSubmissionForm input[type="text"] {
  padding: 7px;
}

.AlertSubmissionForm button {
  margin-top: 1em;
}

.AlertSubmissionForm p {
  margin-top: 1em;
  margin-bottom: 0;
  font-style: italic;
}
