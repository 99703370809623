.AlertListItem {
  padding: 1em;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AlertListItem:nth-child(2n) {
  background-color: #f0f0f0;
}

.AlertListItem strong {
  margin-right: 10px;
}

.AlertListItem__details {
  flex: 1;
  padding-right: 1em;
}

.AlertListItem__delete {
  width: 15px;
  height: 15px;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  opacity: 0.4;
  cursor: pointer;
}

.AlertListItem__delete:hover {
  opacity: 1;
}
