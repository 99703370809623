.LoadingModal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}

.LoadingModal__window {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #fff;
  padding: 1.5em;
  border: 1px solid #ccc;
  width: 300px;
  margin: 0 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
