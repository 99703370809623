.DispatchWindow {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #fff;
  max-width: 100%;
  display: flex;
  max-height: 100%;
  overflow: auto;
}

.DispatchWindow__submitPanel {
  border-right: 1px solid #ccc;
}

.DispatchWindow__submitPanel,
.DispatchWindow__managePanel {
  padding: 2.5em;
  width: 400px;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.DispatchWindow__managePanel h3 {
  margin-top: 0;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .DispatchWindow {
    flex-direction: column;
    border-radius: 0;
  }
  .DispatchWindow__submitPanel,
  .DispatchWindow__managePanel {
    width: 100%;
  }

  .DispatchWindow__submitPanel {
    border-right: 0;
    border-bottom: 1px solid #ccc;
  }
}

@media screen and (max-width: 400px) {
  .DispatchWindow__submitPanel,
  .DispatchWindow__managePanel {
    padding: 5%;
  }
}
