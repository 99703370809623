.AlertList {
  flex: 1;
  width: 100%;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fcfcfc;
  overflow: hidden;
}

.AlertList__items {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media screen and (max-width: 800px) {
  .AlertList__items {
    position: relative;
    height: auto;
  }
}
