.App {
  background-color: #dddddd;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: #223289;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  text-align: center;
  padding: 1em;
  font-size: 1em;
  cursor: pointer;
}

.button:hover {
  background-color: #121c57;
}
