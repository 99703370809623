.Header {
  position: relative;
}

.Header__logo {
  width: 85%;
}

.Header__logout {
  width: 10%;
  height: 0;
  padding: 0;
  padding-bottom: 10%;
  background: none;
  border: none;
  border-radius: 0;
  opacity: 0.4;
  cursor: pointer;
  position: absolute;
  display: block;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.Header__logout:hover {
  opacity: 1;
}
